<template>
  <div class="box content has-background-success-light">
    <p v-if="description" class="title is-6 has-text-info">
      <span class="icon">
        <i class="fal fa-car-side" />
      </span>
      <span>{{ description }}</span>
    </p>
    <p class="title is-6 has-text-dark">
      <span class="tag icon is-rounded is-dark is-valigned">VIN</span>
      <span class="is-valigned">{{ vin }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'VehicleSummaryBox',
  props: {
    description: {
      type: String,
      required: true
    },
    vin: {
      type: String,
      required: false,
      default: () => 'N/A'
    }
  }
}
</script>
